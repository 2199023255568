<template>
    <div class='chat-detail'>
        <div class="detail-manipulate">
            <div class="back-group" @click="goBack">
                <el-image class="back-image" :src="backgroundImage"></el-image>
            </div>
            <el-button type="primary" @click="downloadPdf">下载</el-button>
        </div>
        <div class="detail-group" v-create-ppt-loading="loadingIframe">
            <iframe id="J_Iframe" width="100%" :style="{height: ifHeight }" :src="url" frameborder="0"></iframe>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ChatDetail',
    props: {
        pptUrl: String
    },
    data() {
        return {
            url: '',
            loadingIframe: false
        }
    },
    computed: {
        backgroundImage() {
            return require('@/assets/images/global-back.png')
        },
        ifHeight() {
            return `calc(100vh - 186px)`
        }
    },
    created() {
        this.init()
    },
    mounted() {
        this.getIframe()
    },
    methods: {
        init() {
            this.loadingIframe = true;
            this.url += `https://view.xdocin.com/view?src=${this.pptUrl}` 
            console.log(this.url,'[this.url]')
        },
        getIframe() {
            let _this = this;
            let oIframe = document.getElementById('J_Iframe');
            if (oIframe.attachEvent) {
                oIframe.attachEvent('onload', function(){
                    _this.loadingIframe = false;
                });
            } else {
                oIframe.onload = function() {
                    _this.loadingIframe = false;
                }
            }
        },
        downloadPdf() {
            let a = document.createElement('a'); 
            a.style = 'display: none'; // 创建一个隐藏的a标签
            a.href = this.pptUrl;
            document.body.appendChild(a);
            a.click(); // 触发a标签的click事件
            document.body.removeChild(a);
        },
        goBack() {
            this.$emit('goBack', 'detail');
        }
    }
}
</script>
<style lang='scss' scoped>
.chat-detail {
    margin-top: 6px;
    display: flex;
    flex-direction: column;

    .detail-manipulate {
        width: 100%;
        height: 48px;
        box-sizing: border-box;
        padding: 0 14px;
        background-color: #ffffff;
        border-radius: 4px 0px 0px 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.03);

        .back-group {
            display: inline-block;
            width: 29px;
            height: 29px;
            background: #FFFFFF;
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.17);
            border-radius: 50%;
            cursor: pointer;

            .back-image {
                display: inline-block;
                width: 14px;
                height: 14px;
                margin: 7.5px 0 0 7.5px;
                background-color: #FFFFFF;
            }
        }
    }

    .detail-group {
        flex: 1;
        display: flex;
    }

}
</style>