<template>
    <div class="chat-style">
        <div class="back-group" @click="goBack">
            <el-image class="back-image" :src="backgroundImage"></el-image>
        </div>
        <div class="select-style">
            <div class="style-tab">
                <p class="tip">
                    选择一套模版，开始创建PPT吧~  
                </p>
                <el-button class="begin-create" type="primary" @click="startGenerating">生成PPT</el-button>
            </div>
            <div class="change-block">
                <div class="style">
                    <span class="title">风格:</span>
                    <span 
                        :class="['content', { current: item === templateInfo.style}]" 
                        v-for="(item, index) in styleList"
                        @click="changeStyle(item)"
                    >
                        {{ item }}
                    </span>
                </div>
                <div class="color">
                    <span class="title">颜色:</span>
                    <span 
                        :class="['content', { current: item === templateInfo.color}]" 
                        v-for="(item, index) in colorList"
                        @click="changeColor(item)"
                    >
                        {{ item }}
                    </span>
                </div>
            </div>
        </div>
        <div class="template-list" v-if="records.length > 0">
            <el-scrollbar 
                v-loading="templateLoading"
                overflow-x="hidden" 
                overflow-y="auto" 
                style="height: calc(100vh - 390px)"
            >
                <div    
                    v-for="(item, index) in records" 
                    :class="['template-item', {current: item.templateIndexId === templateInfo.templateIndexId}]"
                    @click="handlerClick(item)"
                >
                    <el-image 
                        style="width: 224px;"
                        :src="item.detailImage.titleCoverImage"
                    >
                    </el-image>
                </div>
            </el-scrollbar>
        </div>
        <div v-else class="no-list">
            暂无数据～
        </div>
    </div>
</template>
<script>
    import {
        ChatPPTModel
    } from '@/models/ChatPPT.js';
    export default {
        name: 'ChatStyle',
        data() {
            return {
                styleList: ["简约","卡通","商务","创意","国风","清新","扁平","插画","节日"],
                colorList: ["蓝色","绿色","红色","紫色","黑色","灰色","黄色","粉色","橙色"],
                templateInfo: {
                    "style": "简约",
                    "color": "蓝色",
                    "industry": "教育培训",
                    "pageNum": 1,
                    "pageSize": 10,
                    "templateIndexId": ""
                },
                records: [],
                templateLoading: false
            }
        },
        computed: {
            backgroundImage() {
                return require('@/assets/images/global-back.png')
            }
        },
        created() {
            this.init();
        },
        methods: {
            init() {
                this.templateLoading = true;
                const chatPPTModel = new ChatPPTModel();
                chatPPTModel.getPPTTemplate(this.templateInfo).then((res) => {
                    if (res.data.code == '200'){
                        this.records = res.data.data.records.map((item) => {
                            item.detailImage = JSON.parse(item.detailImage);
                            return item;
                        });
                        if (this.records.length > 0) {
                            this.templateInfo.templateIndexId = this.records[0].templateIndexId;
                        } else {
                            this.templateInfo.templateIndexId = '';
                        };

                        setTimeout(() => {
                            this.templateLoading = false;
                        }, 2000);
                    }
                })
            },
            handlerClick(item) {
                this.templateInfo.templateIndexId = item.templateIndexId;
            },
            goBack() {
                this.$emit('goBack','style')
            },
            startGenerating() {
                if(!this.templateInfo.templateIndexId) {
                    this.$message.warning('请选择PPT模板')
                } else {
                    this.$emit('startGenerating', this.templateInfo)
                }
                
            },
            changeStyle(data) {
                this.templateInfo.style = data;
                this.init();
            },
            changeColor(data) {
                this.templateInfo.color = data;
                this.init();
            }
        }
    }
</script>
<style lang="scss" scoped>
    .chat-style {
        max-width: 1503.6px;
        box-sizing: border-box;
        background: #FFFFFF;
        box-shadow: 0px 0px 16px 0px rgba(202,224,253,0.4);
        border-radius: 16px;
        margin: 9px auto;
        padding: 10px;
            .back-group {
                display: inline-block;
                width: 29px;
                height: 29px;
                background: #FFFFFF;
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.17);
                border-radius: 50%;
                cursor: pointer;
                .back-image {
                    display: inline-block;
                    width: 14px;
                    height: 14px;
                    margin: 7.5px 0 0 7.5px;
                    background-color: #FFFFFF;
                }
            }
        .select-style {
            padding: 10px;
                .style-tab {
                    width: 100%;
                    height: 48px;
                    box-sizing: border-box;
                    padding: 0 30px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .tip {
                        font-family: Source Han Sans CN;
                        font-weight: bold;
                        font-size: 18px;
                        color: #333333;
                        line-height: 14px;
                    } 
                    .begin-create {
                        width: 110px;
                        height: 40px;
                        background: linear-gradient(90deg, #165DFF 0%, #72C3FC 100%);
                        border-radius: 8px;
                    }
                }
                .change-block {
                    border-top: 1px solid #E5E5E5;
                    border-bottom: 1px solid #E5E5E5;
                    margin: 10px 30px 0px;
                    .style {
                        margin-bottom: 7px;
                        box-sizing: border-box;
                        padding: 8px 0px;
                    }
                    .color {
                        margin-bottom: 10px;
                        box-sizing: border-box;
                        // padding: 0 30px;
                    }
                }
        }
        .title {
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 14px;
            color: #262626;
            line-height: 14px;
            margin-right: 17px;
        }
        .content {
            display: inline-block;
            box-sizing: border-box;
            padding: 1px 10px;
            color: #262626;
            font-size: 14px;
            margin-right: 10px;
            text-align: center;
            cursor: pointer;

            &.current {
                color: #ffffff;
                border-radius: 4px;
                background: #3C7FFF;
                padding: 4px 11px;
            }
        }
        .template-list {
            box-sizing: border-box;
            padding: 10px 40px;
            .template-item {
                float: left;
                height: 125px;
                margin-right: 3px;
                margin-bottom: 8px;
                border-radius: 10px;
                border: 3px solid #ffffff;
                cursor: pointer;
                overflow: hidden;
            }
            .template-item.current {
                border: 3px solid #3C7FFF;
            }
        }
        .no-list {
            height: calc(100vh - 264px);
            box-sizing: border-box;
            padding: 10px;
            color: #999999;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        /deep/ .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
</style>