<template>
    <div class='chat-list'>
        <div 
            class="no-list"
            v-if="list.length === 0"
        >暂无数据</div>
        <el-scrollbar
            v-else 
            overflow-x="hidden" 
            overflow-y="auto"
            style="height: calc(100vh - 184px)"
        >
            <div 
                v-for="(item, index) in list"
                class="list-item"
                @click="handlerClickToDetail(item)"
            >
                <div class="item-image">
                    <el-image lazy class="image" :src="item.coverImg"></el-image>
                </div>
                <div class="item-text">{{ item.title }}</div>
            </div>
        </el-scrollbar>
    </div>
</template>
<script>
    import { ChatPPTModel } from '@/models/ChatPPT.js';
    export default {
        name: 'ChatList',
        data() {
            return {
                list: []
            }
        },
        created() {
            this.init()
        },
        methods: {
            init() {
                this.getList()
            },
            getList() {
                const chatPPTModel = new ChatPPTModel();
                chatPPTModel.chatGetList({}).then((res) => {
                    if (res.data.code == '200') {
                        this.list = res.data.data;
                    } else {
                        this.$message.error(res.data.msg)
                    }
                })
            },
            handlerClickToDetail(item) {
                this.$emit('handlerClickToDetail',item)
            }
        }
    }
</script>
<style lang="scss" scoped>
    .chat-list {
        /deep/ .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        box-sizing: border-box;
        padding: 10px;
        .no-list {
            width: 100%;
            height: calc(100vh - 200px);
            display: flex;
            justify-content: center;
            align-items: center;
            color: #666666;
            font-size: 14px;
        }
        .list-item {
            width: 260px;
            border: 1px solid #eeeeee;
            border-radius: 4px;
            overflow: hidden;
            margin: 0 20px 20px 0;
            float: left;
            cursor: pointer;
            .item-image {
                flex: 1;
                .image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .item-text {
                width: 100%;
                height: 26px;
                line-height: 26px;
                text-align: center;
                color: #666666;
                font-size: 14px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                box-sizing: border-box;
                padding: 0 15px;
            }
        }
    }
</style>