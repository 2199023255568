<template>
    <div class="tabs">
        <div
            v-for="(tab, index) in tabs"
            :key="index"
            :class="['tab-item', { active: idx === tab.id }]"
            @click="handleTabClick(tab)"
        >
        {{ tab.name }}
      </div>
    </div>
  </template>
  
<script>
    export default {
        props: {
            tabs: Array,
            idx: Number
        },
        methods: {
            handleTabClick(tab) {
                this.$emit('handleTabClick', tab);
            }
        },
    };
  </script>
  
<style scoped>
    .tabs {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        margin-top: 6px;
    }
  
    .tab-item {
        height: 40px;
        line-height: 40px;
        margin-right: 5px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 32px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #2B2F33;
        background-color: #F8F8F8;
        border-radius: 6px 6px 0px 0px;
        cursor: pointer;
    }
  
    .tab-item.active {
        background-color: #FFFFFF;
        color: #3C7FFF;
        font-weight: 600;
    }
  </style>